<template>
  <div class="header">
    <div class="header_left">
      <div class="icon">
        <img src="@/assets/images/menu.png" width="30" height="30">
      </div>
      <div class="logo">
        <img src="@/assets/images/logo.png">
      </div>
      <div class="line"></div>
      <div class="menu">
        <router-link tag="div" to="/MoreRank" :class="id==1?'menu_item on':'menu_item'"><span>{{$t('lang.navigation.infos.text1')}}</span></router-link>
        <router-link tag="div" to="/Ai100" :class="id==2?'menu_item on':'menu_item'"><span>{{$t('lang.navigation.infos.text4')}}</span></router-link>
        <el-dropdown @command="handleDexMore"  :class="id==3?'menu_item on':'menu_item'">
          <span class="el-dropdown-link">
            {{$t('lang.navigation.infos.text3')}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="item.value" v-for="item in dropdownDexItem" :key="item.value">
              <div class="pointer_flex">
                <em class="pointer"></em>
                <span>{{$t(item.label)}}</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <div class="menu_item">
          <span>{{$t('lang.navigation.infos.text3')}}</span><i class="el-icon-arrow-down el-icon--right"></i>
        </div> -->
        <div class="menu_item">
          <span>{{$t('lang.navigation.infos.text5')}}</span>
          <!-- <em class="jss23" style="background: rgb(255, 180, 0);">HOT<i style="border-left-color: rgb(255, 180, 0);"></i></em> -->
        </div>
        <div class="menu_item">
          <span>{{$t('lang.navigation.infos.text6')}}</span><i class="el-icon-arrow-down el-icon--right"></i>
        </div>
      </div>
    </div>
    <div class="header_right">
      <div class="menu">
        <!-- <div class="menu_item" @click="zanwei">
          <span>{{$t('lang.wallet')}}</span>
        </div> -->
        <div class="menu_item" @click="loginOutClick" v-if="orderId">
          <span>{{$t('lang.loginOutText')}}</span>
        </div>
        <!-- <div :class="id==6?'menu_item on':'menu_item'" @click="loginClick" v-else>
          <span>{{$t('lang.loginText')}}</span>
        </div> -->
        <div :class="id==7?'menu_item on':'menu_item'" @click="zanwei">
          <span>{{$t('lang.register')}}</span>
        </div>
        <div class="menu_item">
          <em class="iconfont icon-laba">
            <i class="dot"></i>
          </em>
        </div>
        <el-dropdown @command="handleCommand" class="menu_item">
          <div class="el-dropdown-link">
            {{language}}<i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="en">En</el-dropdown-item>
            <el-dropdown-item command="zh">CN</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Header',
  props: {
    id: Number
  },
  data() {
    return {
      orderId: localStorage.orderId,
      language: '',
      dropdownDexItem: [
        {
          value: 1,
          label: 'lang.navigation.dex.text1',
          url: 'https://www.binance.com/zh-CN'
        },
        {
          value: 2,
          label: 'lang.navigation.dex.text2',
          url: 'https://www.huobi.com/zh-cn'
        },
        {
          value: 3,
          label: 'lang.navigation.dex.text3',
          url: 'https://www.okx.com'
        },
        {
          value: 4,
          label: 'lang.navigation.dex.text4',
          url: 'https://app.uniswap.org/#/swap'
        },
        {
          value: 5,
          label: 'lang.navigation.dex.text5',
          url: 'https://pancakeswap.finance'
        },
        {
          value: 6,
          label: 'lang.navigation.dex.text6',
          url: 'https://mdex.co'
        },
        {
          value: 7,
          label: 'lang.navigation.dex.text7',
          url: 'https://trade.dydx.exchange'
        },
        {
          value: 8,
          label: 'lang.navigation.dex.text8',
          url: 'https://coinmarketcap.com'
        },
      ],
    }
  },
  methods: {
    // backHome () {
    //   this.$router.push('/');
    // },
    zanwei () {
      this.$message.error(this.$t('lang.error.e10043'));
    },
    zanwei1 () {
      this.$message.error(this.$t('lang.error.e10043'));
    },
    loginClick () {
      this.$router.push('/Login');
    },
    loginOutClick () {
      this.$confirm(this.$t('lang.确定退出吗？'), this.$t('lang.提示'), {
        confirmButtonText: this.$t('lang.确定'),
        cancelButtonText: this.$t('lang.取消'),
        type: 'warning'
      }).then(() => {
        this.$router.push('/');
        localStorage.mobile = '';
        localStorage.orderId = '';
        localStorage.token = '';
      }).catch(()=>{})
      
    },
    handleClickMore () {
      window.open('https://www.100sunglobal.io','_blank');
    },
    handleDexMore (command) {
      var dropdownDexItem = this.dropdownDexItem;
      dropdownDexItem.map(item=>{
        if(item.value==command){
          window.open(item.url,'_blank');
        }
      })
    },
    handleCommand(command) {
      // this.$message('click on item ' + command);
      if(command== 'zh'){
        // this.reload();
        this.$i18n.locale = 'zh-CN';
        localStorage.locale = 'zh-CN';
        this.language = 'CN'
      }else{
        // this.reload();
        this.$i18n.locale = 'en-US';
        localStorage.locale = 'en-US';
        this.language = 'En'
      }
    },
    reload () {
      window.location.reload();
    },
  },
  mounted () {
    this.$i18n.locale === 'zh-CN' ? this.language = 'CN' : this.language = 'En'
  }
}
</script>

<style lang="less">
@import '../../assets/styles/varibles.less';
.header{
  width: 100%;
  height: @height;
  display: flex;
  z-index: 5;
  font-size: 18px;
  min-width: 1280px;
  background: @bgColor;
  color: @colorWhite;
  box-shadow: 0px 1px 2px rgba(36, 43, 50, 0.2), 0px 0px 2px rgba(36, 43, 50, 0.12), 0px 0px 2px rgba(36, 43, 50, 0.14);
  align-items: center;
  letter-spacing: 0.005em;
  justify-content: space-between;
  .header_left{
    display: flex;
    align-items: center;
    .line{
      width: 2px;
      height: 26px;
      background: #fff;
    }
    .icon{
      width: @height;
      height: @height;
      // background: @bgColor1;
      background: @bgColor2;
      color: @bgColor;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: @font30;
    }
    .logo{
      height: @height;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 14px 25px 14px 25px;
      cursor: pointer;
      &>img{
        width: 223px;
        height: 32px;
      }
    }
    .menu{
      height: @height;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      font-size: @font16;
      margin-left: 12px;
      .menu_item{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 13px;
        cursor: pointer;
        position: relative;
        .jss23{
          position: absolute;
          font-size: @font12;
          top: 3px;
          color: #fff;
          right: 0;
          height: 16px;
          display: block;
          padding: 0 7px;
          transform: scale(.8);
          text-align: center;
          font-weight: bold;
          line-height: 16px;
          border-radius: 2px;
          i{
            left: 0;
            width: 0;
            border: 4px solid transparent;
            bottom: -3px;
            height: 0;
            position: absolute;
          }
        }
        i{
          font-size: @font16;
        }
        .el-dropdown{
          font-size: @font16;
          color: @fontColor;
        }
      }
      .menu_item:hover{
        color: #fff;
      }
      .menu_item.on{
        &>span{
          display: flex;
          align-items: center;
          height: 100%;
          font-weight: @fontWeight[600];
          color: #fff;
          border-bottom: 3px solid #fff;
        }
      }
    }
  }
  .header_right{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    min-width: 200px;
    .menu{
      height: @height;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: @font16;
      .menu_item{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 13px;
        cursor: pointer;
        img{
          margin-right: 5px;
        }
        .icon-laba{
          font-size: 20px;
          position: relative;
        }
      }
      .menu_item:hover{
        color: #fff;
      }
      .menu_item.on{
        &>span{
          display: flex;
          align-items: center;
          height: 100%;
          font-weight: @fontWeight[600];
          color: #fff;
          border-bottom: 3px solid #fff;
        }
      }
      .register{
        color: @basicFontColor;
        padding: 6px 16px;
        font-size: 16px;
        background: @bgColor1;
        min-width: 64px;
        border-radius: 2px;
        cursor: pointer;
      }
      
      .dot{
        display: inline-block;
        width: 4px;
        height: 4px;
        background: #FF2000;
        border-radius: 100%;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
  .el-dropdown{
    font-size: @font16;
    color: @colorWhite;
    cursor: pointer;
  }
}
</style>
