import axios from 'axios';
// import axios from "@/libs/axios";
import QS from 'qs';

//------------------------------------基金----------------------------------------
//接口地址

//mobileInfo
export function mobileInfo(userId,callback){
  sendMsgPost('/sun/mobileInfo/mobile',{
    userId: userId
  },
  function(data){
    callback(data)
  })
}
//echart数据
export function fundPriceList(callback){
  sendMsgGet('/sun/fund/fundPriceList',QS.stringify({
    token:localStorage.token,
  }),
  function(data){
    callback(data)
  })
}

//staking质押提交
export function CreateStakingService(type,num,month,callback){
  sendMsgPost('/vue/CreateStakingService',QS.stringify({
    token:localStorage.token,
    type: type,
    num: num,
    month: month,
  }),
  function(data){
    callback(data)
  })
}

//基金账户
export function fundAllInfo(userId,callback){
  sendMsgPost('/sun/fund/fundAllInfo',{
    userId: userId
  },
  function(data){
    callback(data)
  })
}
//基金购买初始数据
export function buyInfo(userId,currencyTypeName,callback){
  sendMsgPost('/sun/fund/buy/buyInfo',{
    userId: userId,
    currencyTypeName: currencyTypeName
  },
  function(data){
    callback(data)
  })
}

//基金购买
export function addBuyFund(userId,currencyTypeName,sunFundServiceId,buyAmount,dayNumber,callback){
  sendMsgPost('/sun/fund/buy/addBuyFund',{
    userId: userId,
    currencyTypeName: currencyTypeName,
    sunFundServiceId: sunFundServiceId,
    buyAmount: buyAmount,
    dayNumber: dayNumber,
  },
  function(data){
    callback(data)
  })
}

//基金购买订单
export function buyFundOrderList(userId,callback){
  sendMsgPost('/sun/fund/myFund',{
    userId: userId,
  },
  function(data){
    callback(data)
  })
}

//基金产品年化浮动图
export function fundCurveList(currencyTypeName,callback){
  sendMsgPost('sun/fund/fundCurveList',{
    currencyTypeName: currencyTypeName
  },
  function(data){
    callback(data)
  })
}
//锦鲤红包
export function extracting(userId,callback){
  sendMsgPost('/sun/reward/extracting',{
    userId: userId
  },
  function(data){
    callback(data)
  })
}

//参与人数
export function list(userId,callback){
  sendMsgPost('/sun/activity/set/list',{
    userId: userId
  },
  function(data){
    callback(data)
  })
}
//---------------------------------机器人开始-------------------------------------------------------

export function MyService(mobile,callback){
  sendMsgPost('/vue/MyService?mobile='+mobile,QS.stringify({
  }),
  function(data){
    callback(data)
  })
}

export function TuoguanInfo(mobile,callback){
  sendMsgPost('/vue/TuoguanInfo?mobile='+mobile,QS.stringify({
  }),
  function(data){
    callback(data)
  })
}

//系统开关
export function SystemSwitch(isswitch,callback){
  sendMsgPost('/vue/SystemSwitch',QS.stringify({
    switch:isswitch,
  }),
  function(data){
    callback(data)
  })
}

//更换API
export function BlindApi(mobile,accoundid,api_key,api_secret,callback){
  sendMsgPost('/vue/BlindApi?mobile='+mobile,QS.stringify({
    accoundid:accoundid,
    api_key:api_key,
    api_secret:api_secret,
  }),
  function(data){
    callback(data)
  })
}

//策略设置
export function OpenWeituoDetailPage(mobile,callback){
  sendMsgPost('/vue/OpenWeituoDetailPage?mobile='+mobile,QS.stringify({
  }),
  function(data){
    callback(data)
  })
}

//保存参数设置
export function SureMotifyParams(mobile,workstatus,ethinfo,eosinfo,bchinfo,htinfo,callback){
  
  sendMsgPost('/vue/SureMotifyParams?mobile='+mobile,QS.stringify({
    workstatus:workstatus,
    ethinfo:ethinfo,
    eosinfo:eosinfo,
    bchinfo:bchinfo,
    htinfo:htinfo,
  }),
  function(data){
    callback(data)
  })
}

//策略重置
export function delOrder(mobile,callback){
  
  sendMsgPost('/api/delOrder?api_token='+mobile,QS.stringify({
  }),
  function(data){
    callback(data)
  })
}


//----------------------------------python-------------------------------------------------------
// export function login(mobile,callback){
//   sendMsgPost('/jiaoyi/logintest',{
//     mobile: mobile
//   },
//   function(data){
//     callback(data)
//   })
// }
export function login(name,pwd,callback){
  sendMsgPost('/jiaoyi/Login',{
    name: name,
    pwd: pwd,
  },
  function(data){
    callback(data)
  })
}
export function loginOrderId(orderId,callback){
  sendMsgPost('/jiaoyi/logintest',{
    orderId: orderId,
    token: localStorage.token
  },
  function(data){
    callback(data)
  })
}
//ai100
export function aiHundredInfo(userId,callback){
  sendMsgPost('/sun/aiHundred/aiHundredInfo',{
    userId: userId
    // userId: '665159673435154688'
  },
  function(data){
    callback(data)
  })
}
//ai100确认使用
export function aiHundredOrderInsert(userId,serviceMode,callback){
  sendMsgPost('/sun/aiHundred/aiHundredOrderInsert',{
    userId: userId,
    serviceMode: serviceMode,
    // duration: duration
  },
  function(data){
    callback(data)
  })
}

//机器人数据初始化
// export function userInfo(orderId,callback){
//   sendMsgPost('/jiaoyi/userinfo',{
//     orderId: orderId,
//   },
//   function(data){
//     callback(data)
//   })
// }
//我的账户数据
export function userinventory(orderId,callback){
  sendMsgGet('/jiaoyi/userinventory?orderId='+orderId+'&token='+localStorage.token,{
  },
  function(data){
    callback(data)
  })
}

export function userInfo(orderId,callback){
  sendMsgGet('/jiaoyi/userinfo?orderId='+orderId,{
  },
  function(data){
    callback(data)
  })
}

//更换api
export function addapi(orderId,pt,accoundid,api_key,api_secret,status,callback){
  sendMsgPost('/jiaoyi/addapi',{
    orderId: orderId,
    pt: pt,
    accoundid: accoundid,
    api_key: api_key,
    api_secret: api_secret,
    status: status,
  },
  function(data){
    callback(data)
  })
}

//系统巡检
export function addapiGet(orderId,callback){
  sendMsgGet('/jiaoyi/addapi?orderId='+orderId,QS.stringify({
  }),
  function(data){
    callback(data)
  })
}

//正在执行的参数
export function paramefollow(orderId,callback){
  sendMsgGet('/jiaoyi/paramefollow?orderId='+orderId,QS.stringify({
  }),
  function(data){
    callback(data)
  })
}

//一键参数跟随
export function parmeworking(title,callback){
  sendMsgGet('/jiaoyi/parmeworking?title='+title,QS.stringify({
  }),
  function(data){
    callback(data)
  })
}

//保存参数
export function paramesave(orderId,workinterval,data,callback){
  sendMsgPost('/jiaoyi/paramesave',{
    orderId: orderId,
    workinterval: workinterval,
    data: data
  },
  function(data){
    callback(data)
  })
}
//持仓保存
export function marketSell(orderId,status,closing_list,data,callback){
  sendMsgPost('/jiaoyi/marketsell',{
    orderId: orderId,
    status: status,
    closing_list: closing_list,
    data: data
  },
  function(data){
    callback(data)
  })
}
//ai100 echarts
export function echartsvalue(callback){
  sendMsgGet('/jiaoyi/echartsvalue',{
  },
  function(data){
    callback(data)
  })
}

//系统开关

export function systemSetting(orderId,is_switch,callback){
  sendMsgPost('/jiaoyi/systemsetting',{
    orderId: orderId,
    is_switch: is_switch
  },
  function(data){
    callback(data)
  })
}
//恢复上次设置

export function paramerecover(orderId,callback){
  sendMsgGet('/jiaoyi/paramerecover?orderId='+orderId,{
  },
  function(data){
    callback(data)
  })
}

//24小时交易
export function exchangeorder(callback){
  
  sendMsgGet('/jiaoyi/exchangeorder',QS.stringify({
  }),
  function(data){
    callback(data)
  })
}
//一键平仓
export function closeout(orderId,callback){
  sendMsgPost('/jiaoyi/closeout',{
    orderId: orderId,
  },
  function(data){
    callback(data)
  })
}
//会员
export function memberInfo(userId,callback){
  
  sendMsgPost('/sun/member/memberInfo',{
    userId: userId
  },
  function(data){
    callback(data)
  })
}

//是否购买机器人

export function userpolling(mobile,callback){
  
  sendMsgGet('/jiaoyi/userpolling?mobile='+mobile,QS.stringify({
  }),
  function(data){
    callback(data)
  })
}
//机器人数量

export function tacticsmessage(mobile,callback){
  
  sendMsgGet('/jiaoyi/tacticsmessage?mobile='+mobile,QS.stringify({
  }),
  function(data){
    callback(data)
  })
}
//设置充提金额
export function depositwithdraw(orderId,status,usdt_total,callback){
  
  sendMsgPost('/jiaoyi/depositwithdraw',{
    orderId: orderId,
    status: status,
    usdt_total: usdt_total
  },
  function(data){
    callback(data)
  })
}
//设置初始时间
export function changecreatetime(orderId,create_time,callback){
  
  sendMsgPost('/jiaoyi/changecreatetime',{
    orderId: orderId,
    create_time: create_time,
  },
  function(data){
    callback(data)
  })
}
//设置初始金额
export function changeTotal(orderId,total,callback){
  
  sendMsgPost('/jiaoyi/changetotal',{
    orderId: orderId,
    total: total,
  },
  function(data){
    callback(data)
  })
}
//系统公告
export function noticeTitleName(callback){
  sendMsgGet('/sun/notice/noticeTitleName',{
  },
  function(data){
    callback(data)
  })
}
//系统公告详情
export function noticeList(noticeType,callback){
  sendMsgPost('/sun/notice/noticeList',{
    noticeType: noticeType
  },
  function(data){
    callback(data)
  })
}
//排行榜
export function ranklist(callback){
  sendMsgGet('/jiaoyi/seleniumdata',{
  },
  function(data){
    callback(data)
  })
}
//获取大盘数据
export function getDapanInfo(callback){
  sendMsgGet('/sunexsite/dapan',{
  },
  function(data){
    callback(data)
  })
}


//----------------------------------python-------------------------------------------------------

function sendMsgPost(url, params, callback){
  // console.log(' post api is ' + url +' the params is ' + JSON.stringify(params))
  axios.post(url,params,{headers:{'Content-Type':'application/json'}}).then(function (response) {
    // console.log(response.data);
    // var data = JSON.stringify(response.data)
    callback(response.data)
  })
  .catch(function (error) {
    //callback('error')
    console.log(error);
  });
}
function sendMsgGet(url, params, callback){
  // console.log(' post api is ' + url +' the params is ' + JSON.stringify(params))
  axios.get(url,params,{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(function (response) {
    // console.log(response.data);
    // var data = JSON.stringify(response.data)
    callback(response.data)
  })
  .catch(function (error) {
    //callback('error')
    console.log(error);
  });
}
function sendMsgGetJson(url, params, callback){
  // console.log(' post api is ' + url +' the params is ' + JSON.stringify(params))
  console.log(JSON.stringify(params))
  axios.get(url,params,{headers:{'Content-Type':'application/json'}}).then(function (response) {
    // console.log(response.data);
    // var data = JSON.stringify(response.data)
    callback(response.data)
  })
  .catch(function (error) {
    //callback('error')
    console.log(error);
  });
}
export function IsNullOrBe(param){
  if(param==null||param==''||param==undefined){
    return false
  }else{
    return true
  }
}



